import { wrapEmptyRequest } from './util.service'

const oakApiUrl = process.env.VUE_APP_OAK_API_URL

export const doPostOne = wrapEmptyRequest(({ registrationObj }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(registrationObj)
  }

  return fetch(`${oakApiUrl}/registration_request`, requestOptions)
})
