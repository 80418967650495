import { beforeEachRoute } from '@/services/util.service'
import Vue from 'vue'
import Router from 'vue-router'

import SiteRoutes from './site.routes'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    redirect:() => {
      {
        return '/home'
      }
    }
  },
  ...SiteRoutes,

  {
    path: '/blank',
    name: 'blank',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/BlankPage.vue')
  },
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  }]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach = beforeEachRoute

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
