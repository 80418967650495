const retainhubHubName = process.env.VUE_APP_RETAINHUB_NAME

const retainhubOpsApiUrl = process.env.VUE_APP_RETAINHUB_OPS_API_URL

export const commonValidationRules = {
  noRules: [],
  requiredRules: [
    (v) => !!v || 'This field is required'
  ],
  requiredInvitationIdRules: [
    (v) => !!v || 'Invitation Token field is required'
  ],
  requiredFirstNameRules: [
    (v) => !!v || 'First Name field is required'
  ],
  requiredLastNameRules: [
    (v) => !!v || 'Last Name field is required'
  ],
  minLengthTenChars: [
    (v) => !!v || 'This field is required',
    (v) => (v && v.length >= 10) || 'This field must be at least 10 characters'
  ],
  maxLength64Chars: [
    (v) => !!v || 'This field is required',
    (v) => (v && v.length >= 64) || 'This field must be less then 64 characters'
  ],
  emailIdRules: [
    (v) => !!v || 'E-mail is required',
    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ],
  minSelectOneOption: [
    (v) => !!v || 'This field is required',
    (v) => (v && v.length >= 1) || 'Select at least one option'
  ],
  barcodeRules: [
  //  (v) => !!v || 'This field is required',
    (v) => (v && v.length === 13) || 'This field must be equal to 13 characters',
    (v) => /^[a-zA-Z0-9-_ ]+$/.test(v) || 'This field must contain alphanumeric'
  ]

}
export const sliderInformation = {
  labels: ['DRAFT','SUBMITTED', 'APPROVED', 'PACKED', 'READY TO SHIP', 'SHIPPED', 'RECEIVED'],
  labelObjects: [
    { text: 'draft', value: 30 },
    { text: 'submitted', value: 110 },
    { text: 'approved', value: 212 },
    { text: 'packed', value: 20 },
    { text: 'ready to ship', value: 65 },
    { text: 'shipped', value: 120 },
    { text: 'received', value: 54 }
  ],
  statuses:{
    PROCESSING: { icon: 'mdi-refresh', text: 'processing' },
    DRAFT: { icon: 'mdi-pencil', text: 'draft' },
    SUBMITTED: { icon : 'mdi-send', text: 'submitted 5 days ago' },
    APPROVED: { icon : 'mdi-timer-sand-empty', text: 'Estimated delivery in 14 weeks' },
    PACKED:{ icon : 'mdi-timer-sand-empty', text: 'Shipment 1 of 2 expected in 3 weeks' }, 
    READY_TO_SHIP:{ icon : 'mdi-timer-sand-empty', text: 'Expected delivery in 3 weeks' },
    SHIPPED:{ icon : 'mdi-timer-sand-empty', text: 'Expected delivery in 3 weeks' }, 
    RECEIVED:{ icon : 'mdi-gift-outline', text: 'Received today' }
  }

}

export const goToRoute = (name, params, vm) => {
  vm.$router.push({
    name: name,
    params: params
  })

  return true
}
export const validateSelection = (sel, vm) => {
  if (sel.length === 0) {
    vm.toast.show = true
    vm.toast.message = 'You need to select an item first.'
    vm.toast.color = 'error'

    return false
  } else {
    if (sel.length > 1) {
      let message = null

      message = 'You had more then one selection. Assuming the first ....'
      vm.$snotify.success(message, {
        position: 'rightTop',
        showProgressBar: false
      })
    }

    return true
  }
}
export const getSelection = (selectedRec) => {
  const sel = []

  if (selectedRec[0] !== null) {
    sel.push(selectedRec[0])
  }

  return sel
}
export const isoStringToNormalDate = (date) => {
  const newDateTime = Date.parse(date)

  return new Date(newDateTime) // return format Sun Aug 22 2021 19:40:44 GMT+0530 (India Standard Time)
}

export const isoToYMDFormat = (date) => {
  const newDateTime = Date.parse(date)
  const newDate = new Date(newDateTime)
  const resultDate = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, '0') + '-' + newDate.getDate().toString().padStart(2, '0')

  return resultDate     //return format  "2021-08-22"
}

export const calendarDateFormate = (date) => {
  const newDateTime = Date.parse(date)
  const newDate = new Date(newDateTime)
  const resultDate = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, '0') + '-' + newDate.getDate().toString().padStart(2, '0') + ' ' + newDate.getHours().toString().padStart(2, '0') + ':' +
    newDate.getMinutes().toString().padStart(2, '0')

  return resultDate     //return format  "2021-8-22 19:40"
}

export const getLastDayOfCurrentMonth = (startDate) => {
  const givenDate = new Date(startDate)

  const lastDateOfMonth = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0)

  const resultDate = lastDateOfMonth.getFullYear() + '-' + (lastDateOfMonth.getMonth() + 1).toString().padStart(2, '0') + '-' + lastDateOfMonth.getDate().toString().padStart(2, '0')

  return resultDate  //return format  "2021-08-31"
}
export const formatDateAMPM = (date) => {
  const newDate = new Date(date)
  let hours = newDate.getHours()

  let minutes = newDate.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]

  const strTime = monthNames[(newDate.getMonth())] + ' ' + newDate.getDate() + ', ' + newDate.getFullYear() + ' ' + hours + ':' + minutes + ' ' + ampm

  return strTime   //return format "August 22, 2021 7:40 PM"
}

export const getActivityLabel = (item) => {
  let label = ''

  switch (item.action) {
  case 'U':
    label = item.userFullName + ` has modified the details of business ${item.entityLineage.businessEntityName}`
    break
  case 'D':
    label = item.userFullName + ` has removed the details of business ${item.entityLineage.businessEntityName}`
    break
  case 'I':
    label = item.userFullName + ` has created the details of business${item.entityLineage.businessEntityName}`
    break
  case 'A':
    label = item.userFullName + ` has archived the details of business ${item.entityLineage.businessEntityName}`
    break
  default:
    label = item.userFullName + ` has modified the details of business ${item.entityLineage.businessEntityName}`
  }

  return label
}
export const getHoursMinutesAMPM = (date) => {
  const newDate = new Date(date)
  let hours = newDate.getHours()

  let minutes = newDate.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes

  const strTime = hours + ':' + minutes + ' ' + ampm

  return strTime   //return format "August 22, 2021 7:40 PM"
}

export const defaultSortedSizeRanges = [
  '8/9','10/11','12/13','14/15','15/16','17/18','18/19','39/40','39/42','41/42','43/44','43/46','45/46','47/48','XXS','XS','S','M','L','XL','XXL','XXXL','XXS/XS','XS/S','P/S','S/M','M/L','L/XL','XL/XXL','3XL','4XL','O/S','IT 34','IT 36','IT 38','IT 40','IT 42','IT 44','IT 46','IT 48','IT 50','IT 52','IT 54','FR 32','FR 34','FR 36','FR 38','FR 40','FR 42', 'FR 44', 'FR 46', 'FR 48', 'FR 50', 'FR 52', 'US 00', 'US 0', 'US 2', 'US 4', 'US 6', 'US 8', 'US 10', 'US 12',  'US 14', 'US 16', 'UK 4', 'UK 6', 'UK 8', 'UK 10', 'UK 12', 'UK 14', 'UK 16', 'UK 18', 'UK 20','UK 22','UK 24','DK 30','DK 32','DK 34','DK 36','DK 38','DK 40','DK 42','DK 44','DK 46','AUD 4','AUD 6','AUD 8','AUD 10','AUD 12','AUD 14','AUD 16','AUD 18','AUD 20','US 5','US 5.5','US 6.5','US 7','US 7.5','US 8.5','US 9','US 9.5','US 10.5','US 11','UK 2','UK 2.5','UK 3', 'UK 3.5','UK 4.5','UK 5','UK 5.5','UK 6.5','UK 7','UK 7.5','EUR 35','EUR 35.5','EUR 36','EUR 36.5','EUR 37','EUR 37.5','EUR 38','EUR 38.5','EUR 39','EUR 39.5','EUR 40','EUR 40.5','EUR 41','3 Mos','6 Mos','9 Mos','12 Mos','18 Mos','24 Mos'
]

export const getQuantityTotal = (qtys) => {
  let totalQty = 0

  if (qtys) {
    Object.keys(qtys).forEach((k) => {
      Object.keys(qtys[k]).forEach((v) => {
        if (!isNaN(qtys[k][v]) && v)
          totalQty += Number(qtys[k][v])
      })
    })
  }

  return totalQty
}
export const getSizeColorQtyObj = (data) => {
  const item = {}

  Object.keys(data).forEach((size) => {
    Object.keys(data[size]).forEach((obj) => {
      if (obj && size) {
        item.size = size
        item.color = obj
        item.qty = data[size][obj]
      }
    })
  })

  return item
}
export const getSizeColorQuantityList = (data) => {
  const qtyObjects = []

  Object.keys(data).forEach((size) => {
    Object.keys(data[size]).forEach((v) => {
      if (v && data[size][v]) {
        const qtyObject = {}
        const color = {}

        color[v] = data[size][v]
        qtyObject[size] = { ...color }
        qtyObjects.push(getSizeColorQtyObj(qtyObject))
      }
    })
  })

  return qtyObjects

}
export const numberWithCommas = (val) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.00'
}
export const orderStatusColor = (val) => {
  switch (val) {
  case 'draft':
    return '#DC95AF'
  case 'submitted':
    return '#708EC8'
  case 'approved':
    return '#A7D168'
  case 'ready to ship':
    return '#C9DFEA'
  case 'pending':
    return '#00FFFF'
  case 'shipped':
    return '#8D9FAC'
  case 'received':
    return '#00ADE7'
  case 'packed':
    return '#00BFDA'
  case 'cancelled':
    return '#FF4144'  
  default:
    return val
  }
}
export const dateFormateToDMY = (date) => {
  const newDateTime = Date.parse(date)
  const tmp = new Date(newDateTime).toLocaleDateString().split('/')

  return (date = tmp[0] + '/' + tmp[1] + '/' + tmp[2])
}
export const getQuestionAgainstName = (val) => {
  switch (val) {
  case 'order_shipping_address':
    return 'Do you have a shipping address on the order ?'
  case 'order_billing_address':
    return 'Do you have a billing address on the order ?'
  case 'order_shipping_address_matches':
    return 'Does it match the address below ?' 
  case 'order_billing_address_matches':
    return 'Does it match the address below ?'   
  case 'po_number':
    return 'Does the document have an invoice number ?'
  case 'shipping_cost':
    return 'Are there any shipping costs ?' 
  case 'order_discount':
    return 'Is there any discount on the total value of the order ?'
  case 'order_currency':
    return 'Are there any currency specified on the order?' 
  case 'order_detailed_data':
    return 'How detailed is the data on the order ?' 
  case 'info_on_order_documents':
    return 'Are the below information available on the document?' 
  
  default:
    return val
  }
}
export const getQuestionAgainstKey = (val) => {
  switch (val) {
  case 'skuStockKeeping':
    return 'SKU (Stock Keeping Unit)'
  case 'skuQuantityOrdered':
    return 'Quantity ordered' 
  case 'skuItemUnitPrice':
    return 'Item Unit price'
  case 'itemCode':
    return 'Item reference or item code' 
  case 'itemName':
    return 'Item name'
  case 'itemUnitPrice':
    return 'Item Unit price' 
  case 'sizeColor':
    return 'Item size range and / or color' 
  case 'quantityOrdered':
    return 'Quantity ordered' 
  
  default:
    return val
  }
}
export const currenciesWithSymbol = [
  { 'cc':'AED','symbol':'\u062f.\u0625;','name':'UAE dirham' },
  { 'cc':'AFN','symbol':'Afs','name':'Afghan afghani' },
  { 'cc':'ALL','symbol':'L','name':'Albanian lek' },
  { 'cc':'AMD','symbol':'AMD','name':'Armenian dram' },
  { 'cc':'ANG','symbol':'NA\u0192','name':'Netherlands Antillean gulden' },
  { 'cc':'AOA','symbol':'Kz','name':'Angolan kwanza' },
  { 'cc':'ARS','symbol':'$','name':'Argentine peso' },
  { 'cc':'AUD','symbol':'$','name':'Australian dollar' },
  { 'cc':'AWG','symbol':'\u0192','name':'Aruban florin' },
  { 'cc':'AZN','symbol':'AZN','name':'Azerbaijani manat' },
  { 'cc':'BAM','symbol':'KM','name':'Bosnia and Herzegovina konvertibilna marka' },
  { 'cc':'BBD','symbol':'Bds$','name':'Barbadian dollar' },
  { 'cc':'BDT','symbol':'\u09f3','name':'Bangladeshi taka' },
  { 'cc':'BGN','symbol':'BGN','name':'Bulgarian lev' },
  { 'cc':'BHD','symbol':'.\u062f.\u0628','name':'Bahraini dinar' },
  { 'cc':'BIF','symbol':'FBu','name':'Burundi franc' },
  { 'cc':'BMD','symbol':'BD$','name':'Bermudian dollar' },
  { 'cc':'BND','symbol':'B$','name':'Brunei dollar' },
  { 'cc':'BOB','symbol':'Bs.','name':'Bolivian boliviano' },
  { 'cc':'BRL','symbol':'R$','name':'Brazilian real' },
  { 'cc':'BSD','symbol':'B$','name':'Bahamian dollar' },
  { 'cc':'BTN','symbol':'Nu.','name':'Bhutanese ngultrum' },
  { 'cc':'BWP','symbol':'P','name':'Botswana pula' },
  { 'cc':'BYR','symbol':'Br','name':'Belarusian ruble' },
  { 'cc':'BZD','symbol':'BZ$','name':'Belize dollar' },
  { 'cc':'CAD','symbol':'$','name':'Canadian dollar' },
  { 'cc':'CDF','symbol':'F','name':'Congolese franc' },
  { 'cc':'CHF','symbol':'Fr.','name':'Swiss franc' },
  { 'cc':'CLP','symbol':'$','name':'Chilean peso' },
  { 'cc':'CNY','symbol':'\u00a5','name':'Chinese/Yuan renminbi' },
  { 'cc':'COP','symbol':'Col$','name':'Colombian peso' },
  { 'cc':'CRC','symbol':'\u20a1','name':'Costa Rican colon' },
  { 'cc':'CUC','symbol':'$','name':'Cuban peso' },
  { 'cc':'CVE','symbol':'Esc','name':'Cape Verdean escudo' },
  { 'cc':'CZK','symbol':'K\u010d','name':'Czech koruna' },
  { 'cc':'DJF','symbol':'Fdj','name':'Djiboutian franc' },
  { 'cc':'DKK','symbol':'Kr','name':'Danish krone' },
  { 'cc':'DOP','symbol':'RD$','name':'Dominican peso' },
  { 'cc':'DZD','symbol':'\u062f.\u062c','name':'Algerian dinar' },
  { 'cc':'EEK','symbol':'KR','name':'Estonian kroon' },
  { 'cc':'EGP','symbol':'\u00a3','name':'Egyptian pound' },
  { 'cc':'ERN','symbol':'Nfa','name':'Eritrean nakfa' },
  { 'cc':'ETB','symbol':'Br','name':'Ethiopian birr' },
  { 'cc':'EUR','symbol':'\u20ac','name':'European Euro' },
  { 'cc':'FJD','symbol':'FJ$','name':'Fijian dollar' },
  { 'cc':'FKP','symbol':'\u00a3','name':'Falkland Islands pound' },
  { 'cc':'GBP','symbol':'\u00a3','name':'British pound' },
  { 'cc':'GEL','symbol':'GEL','name':'Georgian lari' },
  { 'cc':'GHS','symbol':'GH\u20b5','name':'Ghanaian cedi' },
  { 'cc':'GIP','symbol':'\u00a3','name':'Gibraltar pound' },
  { 'cc':'GMD','symbol':'D','name':'Gambian dalasi' },
  { 'cc':'GNF','symbol':'FG','name':'Guinean franc' },
  { 'cc':'GQE','symbol':'CFA','name':'Central African CFA franc' },
  { 'cc':'GTQ','symbol':'Q','name':'Guatemalan quetzal' },
  { 'cc':'GYD','symbol':'GY$','name':'Guyanese dollar' },
  { 'cc':'HKD','symbol':'HK$','name':'Hong Kong dollar' },
  { 'cc':'HNL','symbol':'L','name':'Honduran lempira' },
  { 'cc':'HRK','symbol':'kn','name':'Croatian kuna' },
  { 'cc':'HTG','symbol':'G','name':'Haitian gourde' },
  { 'cc':'HUF','symbol':'Ft','name':'Hungarian forint' },
  { 'cc':'IDR','symbol':'Rp','name':'Indonesian rupiah' },
  { 'cc':'ILS','symbol':'\u20aa','name':'Israeli new sheqel' },
  { 'cc':'INR','symbol':'\u20B9','name':'Indian rupee' },
  { 'cc':'IQD','symbol':'\u062f.\u0639','name':'Iraqi dinar' },
  { 'cc':'IRR','symbol':'IRR','name':'Iranian rial' },
  { 'cc':'ISK','symbol':'kr','name':'Icelandic kr\u00f3na' },
  { 'cc':'JMD','symbol':'J$','name':'Jamaican dollar' },
  { 'cc':'JOD','symbol':'JOD','name':'Jordanian dinar' },
  { 'cc':'JPY','symbol':'\u00a5','name':'Japanese yen' },
  { 'cc':'KES','symbol':'KSh','name':'Kenyan shilling' },
  { 'cc':'KGS','symbol':'\u0441\u043e\u043c','name':'Kyrgyzstani som' },
  { 'cc':'KHR','symbol':'\u17db','name':'Cambodian riel' },
  { 'cc':'KMF','symbol':'KMF','name':'Comorian franc' },
  { 'cc':'KPW','symbol':'W','name':'North Korean won' },
  { 'cc':'KRW','symbol':'W','name':'South Korean won' },
  { 'cc':'KWD','symbol':'KWD','name':'Kuwaiti dinar' },
  { 'cc':'KYD','symbol':'KY$','name':'Cayman Islands dollar' },
  { 'cc':'KZT','symbol':'T','name':'Kazakhstani tenge' },
  { 'cc':'LAK','symbol':'KN','name':'Lao kip' },
  { 'cc':'LBP','symbol':'\u00a3','name':'Lebanese lira' },
  { 'cc':'LKR','symbol':'Rs','name':'Sri Lankan rupee' },
  { 'cc':'LRD','symbol':'L$','name':'Liberian dollar' },
  { 'cc':'LSL','symbol':'M','name':'Lesotho loti' },
  { 'cc':'LTL','symbol':'Lt','name':'Lithuanian litas' },
  { 'cc':'LVL','symbol':'Ls','name':'Latvian lats' },
  { 'cc':'LYD','symbol':'LD','name':'Libyan dinar' },
  { 'cc':'MAD','symbol':'MAD','name':'Moroccan dirham' },
  { 'cc':'MDL','symbol':'MDL','name':'Moldovan leu' },
  { 'cc':'MGA','symbol':'FMG','name':'Malagasy ariary' },
  { 'cc':'MKD','symbol':'MKD','name':'Macedonian denar' },
  { 'cc':'MMK','symbol':'K','name':'Myanma kyat' },
  { 'cc':'MNT','symbol':'\u20ae','name':'Mongolian tugrik' },
  { 'cc':'MOP','symbol':'P','name':'Macanese pataca' },
  { 'cc':'MRO','symbol':'UM','name':'Mauritanian ouguiya' },
  { 'cc':'MUR','symbol':'Rs','name':'Mauritian rupee' },
  { 'cc':'MVR','symbol':'Rf','name':'Maldivian rufiyaa' },
  { 'cc':'MWK','symbol':'MK','name':'Malawian kwacha' },
  { 'cc':'MXN','symbol':'$','name':'Mexican peso' },
  { 'cc':'MYR','symbol':'RM','name':'Malaysian ringgit' },
  { 'cc':'MZM','symbol':'MTn','name':'Mozambican metical' },
  { 'cc':'NAD','symbol':'N$','name':'Namibian dollar' },
  { 'cc':'NGN','symbol':'\u20a6','name':'Nigerian naira' },
  { 'cc':'NIO','symbol':'C$','name':'Nicaraguan c\u00f3rdoba' },
  { 'cc':'NOK','symbol':'kr','name':'Norwegian krone' },
  { 'cc':'NPR','symbol':'NRs','name':'Nepalese rupee' },
  { 'cc':'NZD','symbol':'NZ$','name':'New Zealand dollar' },
  { 'cc':'OMR','symbol':'OMR','name':'Omani rial' },
  { 'cc':'PAB','symbol':'B./','name':'Panamanian balboa' },
  { 'cc':'PEN','symbol':'S/.','name':'Peruvian nuevo sol' },
  { 'cc':'PGK','symbol':'K','name':'Papua New Guinean kina' },
  { 'cc':'PHP','symbol':'\u20b1','name':'Philippine peso' },
  { 'cc':'PKR','symbol':'Rs.','name':'Pakistani rupee' },
  { 'cc':'PLN','symbol':'z\u0142','name':'Polish zloty' },
  { 'cc':'PYG','symbol':'\u20b2','name':'Paraguayan guarani' },
  { 'cc':'QAR','symbol':'QR','name':'Qatari riyal' },
  { 'cc':'RON','symbol':'L','name':'Romanian leu' },
  { 'cc':'RSD','symbol':'din.','name':'Serbian dinar' },
  { 'cc':'RUB','symbol':'R','name':'Russian ruble' },
  { 'cc':'SAR','symbol':'SR','name':'Saudi riyal' },
  { 'cc':'SBD','symbol':'SI$','name':'Solomon Islands dollar' },
  { 'cc':'SCR','symbol':'SR','name':'Seychellois rupee' },
  { 'cc':'SDG','symbol':'SDG','name':'Sudanese pound' },
  { 'cc':'SEK','symbol':'kr','name':'Swedish krona' },
  { 'cc':'SGD','symbol':'S$','name':'Singapore dollar' },
  { 'cc':'SHP','symbol':'\u00a3','name':'Saint Helena pound' },
  { 'cc':'SLL','symbol':'Le','name':'Sierra Leonean leone' },
  { 'cc':'SOS','symbol':'Sh.','name':'Somali shilling' },
  { 'cc':'SRD','symbol':'$','name':'Surinamese dollar' },
  { 'cc':'SYP','symbol':'LS','name':'Syrian pound' },
  { 'cc':'SZL','symbol':'E','name':'Swazi lilangeni' },
  { 'cc':'THB','symbol':'\u0e3f','name':'Thai baht' },
  { 'cc':'TJS','symbol':'TJS','name':'Tajikistani somoni' },
  { 'cc':'TMT','symbol':'m','name':'Turkmen manat' },
  { 'cc':'TND','symbol':'DT','name':'Tunisian dinar' },
  { 'cc':'TRY','symbol':'TRY','name':'Turkish new lira' },
  { 'cc':'TTD','symbol':'TT$','name':'Trinidad and Tobago dollar' },
  { 'cc':'TWD','symbol':'NT$','name':'New Taiwan dollar' },
  { 'cc':'TZS','symbol':'TZS','name':'Tanzanian shilling' },
  { 'cc':'UAH','symbol':'UAH','name':'Ukrainian hryvnia' },
  { 'cc':'UGX','symbol':'USh','name':'Ugandan shilling' },
  { 'cc':'USD','symbol':'US$','name':'United States dollar' },
  { 'cc':'UYU','symbol':'$U','name':'Uruguayan peso' },
  { 'cc':'UZS','symbol':'UZS','name':'Uzbekistani som' },
  { 'cc':'VEB','symbol':'Bs','name':'Venezuelan bolivar' },
  { 'cc':'VND','symbol':'\u20ab','name':'Vietnamese dong' },
  { 'cc':'VUV','symbol':'VT','name':'Vanuatu vatu' },
  { 'cc':'WST','symbol':'WS$','name':'Samoan tala' },
  { 'cc':'XAF','symbol':'CFA','name':'Central African CFA franc' },
  { 'cc':'XCD','symbol':'EC$','name':'East Caribbean dollar' },
  { 'cc':'XDR','symbol':'SDR','name':'Special Drawing Rights' },
  { 'cc':'XOF','symbol':'CFA','name':'West African CFA franc' },
  { 'cc':'XPF','symbol':'F','name':'CFP franc' },
  { 'cc':'YER','symbol':'YER','name':'Yemeni rial' },
  { 'cc':'ZAR','symbol':'R','name':'South African rand' },
  { 'cc':'ZMK','symbol':'ZK','name':'Zambian kwacha' },
  { 'cc':'ZWR','symbol':'Z$','name':'Zimbabwean dollar' }
]

export const wrapEmptyRequest = (fn) => (...params) =>
  fn(...params)
    .then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
    .catch((error) => handleError(error))

export const handleError = async (errorResponse) => {
  if (!errorResponse.ok) {
    let data = null
    let error = {}

    if (typeof errorResponse.text === 'function') {
      const text = await errorResponse.text()

      try {
        data = JSON.parse(text)
      } catch (e) {
        data = { message: text }
      }
      switch (errorResponse.status) {
      case 403:
        if (typeof Vue.$snotify !== 'undefined') {
          Vue.$snotify.error(data.message)
        }
        //throw data;
        break
      case 401:
        loginInterrupt(window.location.href, 'For enhanced security reasons please sign back in.')
        break
      case 400:
        if (data.code === '22023' && data.message.indexOf('role') >= 0
                      && data.message.indexOf('does not exist')) {
          loginInterrupt(window.location.href, 'For enhanced security reasons please sign back in.')
        }
        else {
          if (typeof Vue.$snotify !== 'undefined') {
            Vue.$snotify.error('Unexpected error while communicating with the cloud')
          }
          //throw data;
        }
        break
      default:
        if (typeof Vue.$snotify !== 'undefined') {
          Vue.$snotify.error('Unexpected error while communicating with the cloud')
        }

              /* error = {
                   text: errorResponse.statusText,
                   code: errorResponse.status,
                   message: nodeEnv ==='production'?"Cloud communication error":data.message,
                   url: errorResponse.url
               };

               throw error;*/

      }//switch
      error = {
        text: errorResponse.statusText,
        code: errorResponse.status,
        message: (nodeEnv === 'production' ? 'Cloud communication error' : data.message),
        url: errorResponse.url
      }
      console.log('Mode', nodeEnv)
      console.log('Error', error)
      throw error
    }
    else {
      if (typeof Vue.$snotify !== 'undefined') {
        Vue.$snotify.error('Unexpected error while communicating with the cloud')
      }

      error = {
        text: errorResponse.statusText,
        code: errorResponse.status,
        message: 'Cloud communication error',
        url: errorResponse.url
      }

      throw error
    }
  }
}
export const loginInterrupt = function (current_url, message) {

  sessionStorage.interrupted_page = current_url
  sessionStorage.interrupt_message = message
  window.location.href = loginUrl

}
export const beforeEachRoute = function (to, from, next) {

  if (!to.matched.length) {
    location.href = to.path
  }
  const requiresRole = to.meta ? to.meta.requiresRole : null

  if (requiresRole) {
    const userAuth = getAuthUser()

    if (userAuth === null || userAuth.grants === null || !userAuth.grants.includes(requiresRole)) {
      loginInterrupt(to.fullPath)
    }
  }

  next()
}
export const customGTMEvent = (action) => {
 
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    'event':action
  })
  console.log('google analytics event called ', action)

  return true
}
export const customGTMEventWithAttributes = (action,obj) => {
 
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    'event':action,
    'registration':obj
  })
  console.log('google analytics event called ', action)

  return true
}