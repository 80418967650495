import { wrapEmptyRequest } from './util.service'

const ashtaApiUrl = process.env.VUE_APP_ASHTA_API_URL

export const doPostOne = wrapEmptyRequest(({ registrationObj }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(registrationObj)
  }

  return fetch(`${ashtaApiUrl}/registration_request`, requestOptions)
})
