export default [
  {
    path: '/home/:referenceCode?',
    name: 'home',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/site/home/HomePage.vue'),
      
    meta: {
      layout: 'simple',
      breadcrumb: { label: 'Home Page' }
    }
  },
  {
    path: '/try/:referenceCode?',
    name: 'try',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/site/try/TryPage.vue'),
      
    meta: {
      layout: 'simple',
      breadcrumb: { label: 'Try Page' }
    }
  },
  // {
  //   path: '/prices',
  //   name: 'prices',
  //   component: () => import(/* webpackChunkName: "site" */ '@/pages/site/prices/PricingPage.vue'),
      
  //   meta: {
  //     layout: 'simple',
  //     breadcrumb: { label: 'Prices Page' }
  //   }
  // },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/site/contact/ContactPage.vue'),
      
    meta: {
      layout: 'simple',
      breadcrumb: { label: 'Contact Page' }
    }
  },
  {
    path: '/signup-service',
    name: 'signup-service',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/site/signupService/signupService.vue'),
       
    meta: {
      layout: 'simple',
      breadcrumb: { label: 'sign up service Page' }
    }
  },
  {
    path: '/error/not-found',
    name: 'error-not-found',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  }, {
    path: '/error/unexpected',
    name: 'error-unexpected',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/error/UnexpectedPage.vue'),
    meta: {
      layout: 'error'
    }
  }, {
    path: '/utility/maintenance',
    name: 'utility-maintenance',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/utility/MaintenancePage.vue'),
    meta: {
      layout: 'auth'
    }
  }, {
    path: '/utility/coming-soon',
    name: 'utility-soon',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/utility/SoonPage.vue'),
    meta: {
      layout: 'auth'
    }
  }, {
    path: '/utility/help',
    name: 'utility-help',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/utility/HelpPage.vue'),
    meta: {
      breadcrumb: { label: 'Help Page' }
    }
  }, {
    path: '/platform/faq',
    name: 'FaqPage',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/platform/FaqPage.vue'),
    meta: {
      breadcrumb: { label: 'Faq Page' }
    }
  },
  {
    path: '/platform/pricing',
    name: 'PricingPage',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/platform/PricingPage.vue'),
    meta: {
      breadcrumb: { label: 'Pricing Page' }
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/site/termsAndConditions/TermsAndConditions.vue'),
    meta: {
      layout: 'simple',
      breadcrumb: { label: 'Terms And Conditions' }
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "site" */ '@/pages/site/privacyPolicy/PrivacyPolicy.vue'),
    meta: {
      layout: 'simple',
      breadcrumb: { label: 'Privacy Policy' }
    }
  }
]
