<template>
  <v-container class="background-color-FFFFFF py-0 px-sm-10" fluid>
    <v-footer light class="white">
      <v-row class="d-flex text-sm-left text-center my-0" >
        <v-col
          cols="12"
          md="6"
          sm="6"
          lg="5"
          class="pt-2 pb-1"
        >
          <router-link
            to="/home"
            class="font-weight-bold text-decoration-none d-flex justify-center justify-sm-start"
          >
            <v-img
              contain
              dark
              class="cursor-pointer"
              max-width="163"
              max-height="43"
              aspect-ratio="1.4"
              :src="require('@/assets/ashta-blue.png')"
            ></v-img>
          </router-link>
          <div class="pt-2 pt-sm-3 pl-2">
            <v-btn
              small
              fab
              elevation="0"
              color="#F7F7F7"
              class="fontsize-16"
              @click="goToPage('https://www.linkedin.com/company/ashta/')"
            >
              in
            </v-btn>
            <v-btn
              small
              fab
              elevation="0"
              class="ml-3"
              color="#F7F7F7"
              @click="goToPage('https://www.instagram.com/ashta.io/')"
            ><v-icon>mdi-instagram</v-icon></v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
          lg="2"
          class="pb-1"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="fontsize-14 comfortaa-bold-font font-color-4D4E4E line-height-18"
              >PRODUCT</v-list-item-title>
              <!--<router-link
                to=""
                class="text-decoration-none font-color-8D9FAC fontsize-14 line-height-18 pt-2"
              >
                ashtra track</router-link>-->
              <!--<router-link to="" class="text-decoration-none font-color-8D9FAC fontsize-14 line-height-18 pt-1">
                
                ashta connect
              </router-link>-->
              <!-- <router-link
                to="/prices"
                class="text-decoration-none fontsize-14 font-color-8D9FAC line-height-18 pt-2"
              >
                Pricing</router-link> -->
              <a class="text-decoration-none font-color-8D9FAC  fontsize-14 line-height-18 pt-1" :href="ashtaAppUrl+`/gate/signin`"> Login</a>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
          lg="2"
          class="pb-1"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="fontsize-14 comfortaa-bold-font font-color-4D4E4E line-height-18 "
              >COMPANY</v-list-item-title>
              <!--<router-link to="" class="default-cursor-pointer text-decoration-none font-color-8D9FAC fontsize-14 line-height-18 pt-2">
                About us
              </router-link>-->
              <div class="default-cursor-pointer text-decoration-none font-color-8D9FAC fontsize-14 line-height-18 pt-2">
                About us
              </div>
              <router-link
                to="/terms-and-conditions"
                class="text-decoration-none fontsize-14 font-color-8D9FAC line-height-18 pt-2"
              >
                Terms and Conditions</router-link>
              <router-link
                to="/privacy-policy"
                class="text-decoration-none fontsize-14 font-color-8D9FAC line-height-18 pt-2"
              >
                Privacy policy</router-link>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
          lg="3"
          class="pb-1"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="fontsize-14 comfortaa-bold-font font-color-4D4E4E line-height-18 "
              >CONTACT</v-list-item-title>
              <a href="mailto:info@ashta.io" class="text-decoration-none fontsize-14 font-color-8D9FAC line-height-18 pt-2">
                info@ashta.io</a>
              <a href="tel:+33 649019443" class="text-decoration-none fontsize-14 font-color-8D9FAC line-height-18 pt-1">
                +33 649019443</a>
              <v-list-item-subtitle class="fontsize-14 font-color-8D9FAC line-height-18 pt-1">
                <span class="font-color-8D9FAC">27 Rue Bollu, 75009 Paris</span></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
const ashtaAppUrl = process.env.VUE_APP_ASHTA_APP_URL

export default {
  name: 'NewFooterComponent',
  data() {
    return {
      ashtaAppUrl
    }
  },
  methods: {
    goToPage(url) {
      window.open(url)
    }
  }
}
</script>
