<template>
  <div v-resize="resizeView">
    <v-navigation-drawer
      v-if="!$vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <v-list nav dense class="no-background pa-6">
        <v-list-item-group>
          <!--<v-list-item>
            <router-link
              to=""
              class="px-sm-2 px-1 fontsize-16 line-height-18 comfortaa-regular-font text-decoration-none font-color-4D4E4E"
            >
              ashta track
            </router-link>
          </v-list-item>-->
          <!-- <v-list-item>

            <router-link
              to=""
              class="px-sm-2 px-1 fontsize-16 line-height-18 comfortaa-regular-font text-decoration-none font-color-4D4E4E"
            >
              ashta connect
            </router-link>
          </v-list-item>-->
          <v-list-item>

            <router-link
              class="px-sm-2 px-1 text-decoration-none fontsize-16 line-height-18 comfortaa-regular-font font-color-4D4E4E  header-route-button"
              to="/prices"
            >
              Pricing
            </router-link>
          </v-list-item>
          <!--<v-list-item>

            <router-link
              class="px-sm-2 px-1 text-decoration-none fontsize-16 line-height-18 comfortaa-regular-font font-color-4D4E4E"
              to=""
            >
              About
            </router-link>
          </v-list-item>-->
          <v-list-item>
          
            <a class="px-sm-2 px-1 fontsize-16 line-height-18 text-decoration-none comfortaa-regular-font font-color-4D4E4E" :href="ashtaAppUrl+`/gate/signin`"> Login</a>
          </v-list-item>
          <v-list-item>

            <v-btn
              rounded
              depressed
              class="px-2 fontsize-16 line-height-18 comfortaa-regular-font white--text  background-color-gradient-A7D168"
              @click="tryItFreeDialogShow()"
            >
              Try for free
            </v-btn>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- Navigation menu -->
    </v-navigation-drawer>
    <v-app-bar fixed flat height="60" :color="gateModuleFlag ? '' : ''">
      <v-container id="home" class="pa-0 fill-height px-sm-12" fluid>
        <v-card class="flex-grow-1 d-flex" flat>
          <div class="d-flex flex-grow-1 align-center">
            <div class="d-flex d-md-none align-center w-100">
              <router-link
                to="/home"
                class="font-weight-bold text-decoration-none d-flex"
              >
                <v-img
                  contain
                  dark
                  max-width="163"
                  max-height="43"
                  :src="require('@/assets/ashta-blue.png')"
                ></v-img>
              </router-link>
              <v-app-bar-nav-icon
                class="d-flex d-md-none ml-auto"
                color="#4D4D4D"
                @click.stop="drawer = !drawer"
              ></v-app-bar-nav-icon>
             
            </div>
            <div class="d-none d-md-flex w-100">
              <v-row
                align="center"
                justify="center"
              >
                <!-- header for website -->
                <v-col cols="10" sm="10" md="10" class="d-flex align-center pa-0">
                  <router-link
                    to="/home"
                    class="font-weight-bold text-decoration-none d-flex mr-5 pb-1 "
                  >
                    <v-img
                      contain
                      dark
                      max-width="163"
                      max-height="43"
                      :src="require('@/assets/ashta-blue.png')"
                    ></v-img>
                  </router-link>
                  <!--<router-link
                    to=""
                    class="px-sm-2 px-1 fontsize-16 line-height-18 comfortaa-regular-font text-decoration-none font-color-4D4E4E"
                  >
                    ashta track
                  </router-link>-->
                  <!--<router-link
                    to=""
                    class="px-sm-2 px-1 fontsize-16 line-height-18 comfortaa-regular-font text-decoration-none font-color-4D4E4E"
                  >
                    ashta connect
                  </router-link>-->
                  <!-- <router-link
                    class="px-sm-2 px-1 text-decoration-none fontsize-16 line-height-18 comfortaa-regular-font font-color-4D4E4E  header-route-button"
                    to="/prices"
                  >
                    Pricing
                  </router-link> -->
                  <!--<router-link
                    class="px-sm-2 px-1 text-decoration-none fontsize-16 line-height-18 comfortaa-regular-font font-color-4D4E4E"
                    to=""
                  >
                    About
                  </router-link>-->
                    
                </v-col>

                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  class="pa-0"
                >
                  <div class="d-sm-flex align-center justify-end">
                    <a class="px-sm-2 px-1 fontsize-16 line-height-18 text-decoration-none comfortaa-regular-font font-color-4D4E4E" :href="ashtaAppUrl+`/gate/signin`"> Login</a>
                    <v-btn
                      rounded
                      depressed
                      class="px-sm-4 px-2 fontsize-16 comfortaa-regular-font white--text  background-color-gradient-A7D168"
                      @click="tryItFreeDialogShow()"
                    >
                      Try for free
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-container>
    </v-app-bar>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import eventBus from '@/pages/EventBus.vue'
const ashtaAppUrl = process.env.VUE_APP_ASHTA_APP_URL

import { customGTMEvent } from '@/services/util.service'

export default {
  name: 'Header',
  components: {  },
  props: {
    // Text to copy to clipboard
    gateModuleFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ashtaAppUrl,
      tryItFreeDialog: false,
      drawer: null
    }
  },
  computed: {
    ...mapState('app', ['menuTheme'])
  },
  created() {},
  methods: {
    resizeView() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.drawer = false
      }
    },
    tryItFreeDialogShow() {
      // customGTMEvent('trial_form_open')

      eventBus.$emit('tryItFreeDialogShow')
    },
    goToDiv(param) {
      this.$vuetify.goTo(param)
      this.drawer = false
    }
  }
}
</script>
<style src="./Header.scss" lang="scss" />
