import  { doPostOne } from '@/services/registration_request.service'

import { snakeCase } from 'lodash'
import createHumps from 'lodash-humps/lib/createHumps'
import { getField, updateField } from 'vuex-map-fields'
const snakes = createHumps(snakeCase)

export const registrationRequestOne = {
  namespaced: true,
  state: {
    one: null,
    inProgress: true,
    peopleOptions: ['0-1', '2-5', '6-10', '11-50', '50-1,000,000'],
    currentRoleOptions: ['Sales', 'Buyer', 'Ceo', 'Finance / Accounting', 'Logistics', 'Admin', 'Other'],
    ownerEmail:''
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setOwnerEmail(state, ownerEmail) {
      state.ownerEmail = ownerEmail
    }

  },
  actions: {
    new({ commit },{ ownerEmail, appId }) {
      commit('inProgress', false)
      commit('setOne', {
        appId: appId,
        requestType: 'invitation',
        firstName:null,
        lastName: null,
        email:ownerEmail,
        phoneNumber:null,
        metadata:{
          receiveNewsConsent: false,
          peopleCountA: null,
          peopleCountB: null,
          companyName:null,
          companyIndustry:null,
          businessRole: null,
          businessEntityId: null,
          inviterUserId: null,
          inviterUserName : null,
          inviterBusinessName: null,
          inviterBusinessId: null,
          inviterBusinessType : null,
          referenceCode:null
        }
      })
    },

    create: async({ commit, state }) => {
      commit('inProgress', true)
      try {
        await doPostOne({ registrationObj:snakes(state.one) })

        // commit('setOne', humps(created))

        return { data: true }
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        console.log('Error in register', err)
      } finally {
        commit('inProgress', false)
      }
    },
    saveOwnerEmail: async({ commit },{ ownerEmail }) => {
      commit('inProgress', true)
      try {
        commit('setOwnerEmail', ownerEmail)
        commit('inProgress', false)
      }
      // eslint-disable-next-line no-useless-catch
      catch (err) {
        console.log('Error in register', err)
      } finally {
        commit('inProgress', false)
      }
    }

  }

}